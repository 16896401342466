import React from "react"
import Layout from "../../components/layout"
import { Link, graphql } from "gatsby"

import { Container, Row, Card, Col } from 'react-bootstrap';
import Img from "gatsby-image"
import SEO from "../../components/seo"

// import { Image } from "react-bootstrap";


export default ({ data }) => {
  return (
    <Layout>
      <SEO title="The Members" />
      <Container>
          <h1 className="text-center">The Members of the Kia Orana Podcast</h1>

          <Container className="singleCol text-center">
      <p className="text-center">
      <hr />
        In addition to being on the podcast, the following businesses have become members of the Kia Orana Podcast. We encourage the listeners of the Kia Orana Podcast to patronize their businesses if possible when in the Cook Islands. 
        <hr />
      </p>
    </Container>
    <Row>
          {data.latestEpisodes.edges.map(({ node }) => (
            <Col lg={4} xs={12} key={node.id}>
              
                <Card className="mb-4">
                  <Link to={node.fields.slug} className="card-link">
                    <Img 
                      fluid={node.frontmatter.Image01.childImageSharp.fluid} 
                      alt={node.frontmatter.title + " Cover"}
                      className="card-img-top"
                      fadeIn
                    />
                    </Link>
                
                  <Card.Body>
                    <h3>Episode no. {node.frontmatter.episode}</h3>
                    {node.frontmatter.titleOfEpisode ? 
                    (
                      <h2><Link to={node.fields.slug} className="card-link">{node.frontmatter.titleOfEpisode}</Link></h2>
                    ) : 
                    <h2><Link to={node.fields.slug} className="card-link">{node.frontmatter.title}</Link></h2> 
                  }
                    
                    {/* <p>{node.frontmatter.recap}</p> */}
                    {/* <Card.Text>
                    <p>{node.excerpt}</p>
                    </Card.Text> */}
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </Card.Body>
                  {/* <Card.Footer className="text-muted">
                    <Link to={"/explore/" + node.frontmatter.categorySlug}>
                    {node.frontmatter.category}
                    </Link></Card.Footer> */}
                </Card>
            </Col>
            ))}
            </Row>
        </Container>
    </Layout>
  )
}
export const pageQuery = graphql`
  query {
    latestEpisodes: allMarkdownRemark(
      sort: {fields: frontmatter___episode, order: DESC}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            titleOfEpisode
            episode
            category
            categorySlug
            recap
            Image01 {
              childImageSharp {
                fluid(maxWidth: 700, maxHeight: 400, cropFocus: CENTER) {
                  base64
                tracedSVG
                aspectRatio
                srcWebp
                srcSetWebp
                originalName
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`